import React, { useEffect, useState } from "react";
import Head from "next/head";
import striptags from "striptags";
import { useSelector } from "react-redux";
import dynamic from "next/dynamic";

const Home = dynamic(() => import("../components/Home/Home"), { ssr: true });
const Layout = dynamic(() => import("../components/common/Layout/Layout"), {
  ssr: true,
});
const Loader = dynamic(() => import("../components/common/Loader/Loader"), {
  ssr: true,
});

function HomePage({ apiData }) {
  const [loader, setLoader] = useState(true);

  const homeData = apiData?.detail;
  const title = apiData?.page_title;
  const seo_title = apiData?.meta?.seo_title;
  const search_description = apiData?.meta?.search_description;
  const canonicalUrl = apiData?.canonical_url;

  const structuredData = apiData?.struct_org_extra_json;
  const formatJson = (json) => {
		return {
			__html: `${json}`,
		};
	}

  const footerData = useSelector(
    (state) => state.headerFooterData.headerFooterDataList
  );

  useEffect(() => {
    if (footerData && apiData) {
      setLoader(false);
    }
  }, []);

  return (
    <div>
      <Head>
        <title>{striptags(title)}</title>

        <meta name="title" content={seo_title} key="og-title" />

        <meta name="description" content={search_description} key="og-desc" />
        <link rel="canonical" href={canonicalUrl} />
        {structuredData && (
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={formatJson(structuredData)}
          />
        )}
      </Head>
      <Layout
        webLogo={
          footerData &&
          footerData?.site_configuration &&
          footerData?.site_configuration[0]?.logo?.url
        }
      >
        {loader && <Loader />}
        <Home homeData={homeData} />
      </Layout>
    </div>
  );
}

export async function getServerSideProps() {
  // Fetch data from your API
  const response = await fetch(
    process.env.NEXT_PUBLIC_API_ENDPOINT + "/cms/pages/home/",
    { next: { revalidate: 3600 } }
  );

  const apiData = await response.json();

  return {
    props: {
      apiData,
    },
  };
}

export default HomePage;
